<template>
  <div style="width:100%;height:100%">
    <agc-svg name="loading" size="100" v-if="savingDialogVisible" class="loading" />
    <div v-else>
      <div>
        <div class="page-header">
          <agc-page-header
            :show-back="true"
            @back="showSaveOrder(1)"
            :title="$t('ctp.translationServices')"
            :description="$t('ctp.translationDescription')"
          >
            <el-link type="primary" target="_blank" :href="helpLink">
              {{ $t("ctp.helpDocument") }}
            </el-link>
          </agc-page-header>
        </div>
        <div class="order-create-main">
          <div class="chooseLanguage">
            {{ $t("ctp.chooseLang") }}
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="300px">
            <div class="chooseTar">
              <!-- 选择源语言 -->
              <el-form-item
                :label="$t('ctp.originLanguage') + $t('ctp.colon')"
                prop="currentSourceLang.name"
                :rules="rules.currentSourceLang"
              >
                <el-select v-model="ruleForm.currentSourceLang" class="selectSourceLang">
                  <el-option
                    v-for="item in sourceLang"
                    :key="item"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="chooseTarget">
              <!-- 选择目标语言 -->
              <el-form-item
                :label="$t('ctp.targetLanguage') + $t('ctp.colon')"
                prop="chooseList"
                :rules="rules.chooseList"
                :show-message="false"
              >
                <button type="button" class="order-empty error-div" :class="{'target-border-color': isTargetLanguage}" @click="chooselan">
                  {{ $t("ctp.chooseCountryLang") }}
                </button>
                <span
                  class="color03"
                  v-if="ruleForm.chooseList.length"
                >{{ $t("ctp.YouChoosedLang", {num: ruleForm.chooseList.length}) }}</span>
                <div v-show="isTargetLanguage" class="targe-error">
                  <agc-icon name="warning" size="9" color="#fa2a2d" />
                  <span class="error-font">{{ $t('ctp.tips.chooseTargetLang') }}</span>
                </div>
              </el-form-item>
            </div>
            <el-dialog :title="$t('ctp.targetLanguage')" :visible.sync="lang01" width="480px">
              <div class="Target02">
                <table style="position: relative;">
                  <tr class="Target03">
                    <td class="Target04">
                      <el-input
                        v-model="search"
                        class="Target05"
                        :placeholder="$t('ctp.seachLang')"
                        prefix-icon="el-icon-search"
                      />
                    </td>
                    <td class="Target06">
                      <span class="Target07">
                        <i18n path="ctp.choosedLang" tag="span">
                          <span class="color00">{{ ruleForm.chooseList.length }}</span>
                        </i18n>
                      </span>
                      <el-link
                        type="primary"
                        class="Target08"
                        @click="deleteAll"
                      >
                        {{ $t("ctp.deleteAll") }}
                      </el-link>
                    </td>
                  </tr>
                  <tr>
                    <td class="Target04">
                      <div class="Target09">
                        <div class="lang-checkbox-div checkAll" :key="all" :value="all">
                          <span class="yh03">-1</span>
                          <input
                            class="language-checkbox yh02 Target10"
                            ref="checkAll"
                            @click="addAll"
                            type="checkbox"
                          >
                          <span
                            class="languageNameClass"
                            @click="addCheckAll"
                          >{{ $t("ctp.selectAll") }}</span>
                        </div>
                        <div
                          v-for="(lang00, index) in searchList"
                          :key="index"
                          class="lang-checkbox-div"
                          v-show="ruleForm.currentSourceLang.value !== lang00.value"
                          :class="
                            ruleForm.chooseList.includes(lang00)
                              ? 'lang-checkbox-div-bgcolor'
                              : ''
                          "
                          style="display:flex;"
                        >
                          <span class="yh03">{{ lang00.value }}</span>
                          <input
                            class="language-checkbox yh02 Target10"
                            @click="add($event, lang00.value)"
                            type="checkbox"
                          >
                          <span
                            class="languageNameClass"
                            @click="addCheck($event, lang00.value)"
                          >{{ lang00.name }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="Target12">
                        <div
                          v-for="(lang00, index) in ruleForm.chooseList"
                          :key="index"
                          class="Target13"
                        >
                          <table class="app27">
                            <tr>
                              <td class="app28">
                                <el-link
                                  type="primary"
                                  @click="deleteTar(lang00.value)"
                                >
                                  {{ lang00.name }}
                                </el-link>
                              </td>
                              <td class="app29">
                                <el-link
                                  type="default"
                                  @click="deleteTar(lang00.value)"
                                  class="Target16"
                                >
                                  <i class="el-icon-close gray" />
                                </el-link>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="Target14">
                      <el-button
                        type="primary"
                        @click="lang01 = false"
                        class="button-ok"
                      >
                        {{ $t("ctp.ok") }}
                      </el-button>
                    </td>
                  </tr>
                </table>
              </div>
            </el-dialog>
            <el-divider />
            <div>
              <div class="app01">
                {{ $t("ctp.chooseContent") }}
              </div>
              <div class="order-title-sub">
                <el-checkbox class="app02" v-model="spread1">
                  {{ $t("ctp.appDetails") }}
                </el-checkbox>
              </div>
              <div>
                <ul class="order-choose-lang" v-if="spread1">
                  <li>
                    <!-- 应用名称 -->
                    <el-form-item
                      :label="$t('ctp.appName')"
                      prop="applicationName"
                      :rules="rules.applicationName"
                    >
                      <el-input
                        type="text"
                        rows="1"
                        maxlength="64"
                        show-word-limit="true"
                        v-model="ruleForm.applicationName"
                        class="app04"
                      />
                    </el-form-item>
                  </li>
                  <li id="area-li">
                    <!-- 应用介绍 -->
                    <el-form-item
                      :label="$t('ctp.appIntroduction')"
                      prop="appIntroduce"
                      :rules="rules.appIntroduce"
                    >
                      <el-input
                        type="textarea"
                        rows="7"
                        maxlength="8000"
                        show-word-limit="true"
                        v-model="ruleForm.appIntroduce"
                        class="app04"
                      />
                    </el-form-item>
                  </li>
                  <li>
                    <!-- 一句话简介 -->
                    <el-form-item
                      :label="$t('ctp.appshortIntroduct')"
                      prop="appIntroduceInshort"
                      :rules="rules.appIntroduceInshort"
                    >
                      <el-input
                        type="text"
                        rows="1"
                        maxlength="80"
                        show-word-limit="true"
                        v-model="ruleForm.appIntroduceInshort"
                        class="app04"
                      />
                    </el-form-item>
                  </li>
                  <!-- 新版本特性 -->
                  <li id="area-li">
                    <el-form-item :label="$t('ctp.feature')" prop="appFeatures">
                      <el-input
                        type="textarea"
                        rows="7"
                        maxlength="500"
                        show-word-limit="true"
                        v-model="ruleForm.appFeatures"
                        class="app04"
                      />
                    </el-form-item>
                  </li>
                </ul>
              </div>
            </div>
            <div class="apptext-div">
              <div class="order-title-sub app07">
                <el-checkbox class="app08" v-model="spread2" :disabled="isDisable">
                  {{ $t("ctp.appText") }}
                </el-checkbox>
              </div>
              <div v-if="spread2" class="app09">
                <div class="app10">
                  {{ $t("ctp.privacy") }}
                </div>
                <div>
                  <div class="uploadLocalFile">
                    <el-form-item prop="sourceFilesInput" :rules="rules.sourceFilesInput">
                      <span slot="label">
                        {{ $t("ctp.uploadLocalFile") }}
                        <el-tooltip popper-class="tips-box" effect="light" placement="top-start">
                          <div slot="content">
                            {{ $t("ctp.fileFormat") }}
                            <br>
                            {{ $t("ctp.contentFormat") }}
                            <br>
                          </div>
                          <span id="icon-uploadTip">
                            <agc-icon name="question" color="#c5c5c5" size="18" />
                          </span>
                        </el-tooltip>
                        {{ $t("ctp.colon") }}
                      </span>
                      <el-upload
                        action
                        class="app15"
                        :auto-upload="false"
                        :on-change="sourcefile"
                        multiple
                        :limit="allowFileNums"
                        :on-remove="removeSourceFile"
                        :accept="allowFileType"
                        ref="uploadingLocalFile"
                        :file-list="ruleForm.sourceFiles"
                        :on-preview="handlePreviewSource"
                        :on-exceed="handleExceed"
                      >
                        <el-button>{{ $t("ctp.upload") }}</el-button>
                      </el-upload>
                      <el-button
                        class="contentTemplate"
                        type="text"
                        @click="
                          storageDownLoadFile('/template/translateTemplate.rar')
                        "
                      >
                        {{ $t("ctp.contentTemplate") }}
                      </el-button>
                      <span class="sourcefile-message">{{ sourcefileMessage }}</span>
                      <el-input
                        v-model="ruleForm.sourceFilesInput"
                        v-show="false"
                      />
                    </el-form-item>
                  </div>
                  <div class="order-upload-outer">
                    <span
                      class="app16"
                    >{{ $t("ctp.manuscriptFormat",{type: FileType,num: allowFileNums,size: allowFileSize}) }}</span>
                  </div>
                </div>
              </div>
              <!-- 未勾选错误提示 -->
              <div v-show="isSelect" class="error-choose-appdetail">
                <agc-icon name="warning" size="9" color="#fa2a2d" />
                <span class="error-font">{{ $t('ctp.tips.chooseAppDetail') }}</span>
              </div>
              <div>
                <div class="uploadLocalFile uploadScreenshot" :class="lang==='ru'?'ru_pl':'zh_pl'">
                  <el-form-item prop="shotscreenFiles" :label="$t('ctp.screenshot')">
                    <el-upload
                      action
                      :auto-upload="false"
                      :on-change="screenShots"
                      multiple
                      :limit="allowScreenNums"
                      :on-remove="onRemoveScreenFile"
                      :accept="allowScreenType"
                      :file-list="shotscreenFiles"
                      ref="screenshotFilePath"
                      :on-preview="handlePreviewScreen"
                      :on-exceed="handleExceed"
                    >
                      <el-button>{{ $t("ctp.upload") }}</el-button>
                    </el-upload>
                  </el-form-item>
                  <span class="screenShots_message">{{ screenShotsMessage }}</span>
                </div>
                <div class="order-upload-outer">
                  <span class="app18">
                    {{ $t("ctp.screenshotExplanation",
                          {type: ScreenType,num: allowScreenNums,size: allowScreenSize}) }}
                  </span>
                </div>
              </div>
              <div class="order-title-sub app21">
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.Reuse") }}</span>
                <div class="app22">
                  <el-radio v-model="repeatUse" label="true" name="platform00">
                    {{ $t("ctp.yes") }}
                  </el-radio>
                  <el-radio v-model="repeatUse" label="false" name="platform00">
                    {{ $t("ctp.no") }}
                  </el-radio>
                </div>
                <div v-if="spread3" class="app19">
                  <span class="app20">{{ $t("ctp.contentPreprocessing") }}</span>
                </div>
                <!-- 重复使用未勾选错误提示 -->
                <div v-show="isRepeatUse">
                  <agc-icon name="warning" size="9" color="#fa2a2d" />
                  <span class="error-font">{{ $t('ctp.tips.contentPreprocessing') }}</span>
                </div>
              </div>
              <div class="order-title-sub app21">
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.contributePlatform") }}</span>
                <div class="app22">
                  <el-radio v-model="platformUse" label="true" name="platform">
                    {{ $t("ctp.yes") }}
                  </el-radio>
                  <el-radio v-model="platformUse" label="false" name="platform">
                    {{ $t("ctp.no") }}
                  </el-radio>
                </div>
                <!-- 重复使用未勾选错误提示 -->
                <div v-show="isPlatformUse">
                  <agc-icon name="warning" size="9" color="#fa2a2d" />
                  <span class="error-font">{{ $t('ctp.tips.contributePlatform') }}</span>
                </div>
              </div>
            </div>
            <!-- the next -->
            <div class="order-next">
              <el-button @click="cancel">
                {{ $t("ctp.cancel") }}
              </el-button>
              <el-button @click="showSaveOrder(0)">
                &nbsp;{{ $t("ctp.save") }}&nbsp;
              </el-button>
              <el-button type="primary" @click="skipnext">
                {{ $t("ctp.next") }}
              </el-button>
            </div>
          </el-form>
        </div>
        <agc-dialog :title="$t('ctp.wordCount')" :visible.sync="payingDialogVisible" width="480px">
          <div class="paying-dialog">
            <span>{{ $t("ctp.wordCounting") }}</span>
          </div>
        </agc-dialog>

        <agc-dialog
          :title="$t('ctp.warning')"
          :visible.sync="sensitiveWordsDialogVisible"
          width="480px"
          @close="sensitiveWordsDialogVisible = false"
        >
          <div class="css07">
            <div>{{ $t("ctp.tips.sensitiveContent") }}</div>
          </div>
          <div v-if="sensitiveWordsMessage01 !== ''">
            {{ $t("ctp.appName") }}{{ $t("ctp.colon")
            }}{{ $t("ctp.violationContent") }}&nbsp;
            <span
              class="order-color-red"
            >{{ sensitiveWordsMessage01 }}</span>&nbsp;
          </div>
          <div v-if="sensitiveWordsMessage02 !== ''">
            {{ $t("ctp.appIntroduction") }}{{ $t("ctp.colon")
            }}{{ $t("ctp.violationContent") }}&nbsp;
            <span
              class="order-color-red"
            >{{ sensitiveWordsMessage02 }}</span>&nbsp;
          </div>
          <div v-if="sensitiveWordsMessage03 !== ''">
            {{ $t("ctp.appshortIntroduct") }}{{ $t("ctp.colon")
            }}{{ $t("ctp.violationContent") }}&nbsp;
            <span
              class="order-color-red"
            >{{ sensitiveWordsMessage03 }}</span>&nbsp;
          </div>
          <div v-if="sensitiveWordsMessage05 !== ''">
            {{ $t("ctp.feature") }}{{ $t("ctp.colon")
            }}{{ $t("ctp.violationContent") }}&nbsp;
            <span
              class="order-color-red"
            >{{ sensitiveWordsMessage05 }}</span>&nbsp;
          </div>
          <div v-if="sensitiveWordsMessage04 !== ''">
            {{ $t("ctp.translateDocuments") }}{{ $t("ctp.colon")
            }}{{ $t("ctp.violationContent") }}&nbsp;
            <span
              class="order-color-red"
            >{{ sensitiveWordsMessage04 }}</span>&nbsp;
          </div>
          <div class="css06">
            <span slot="footer">
              <el-button type="primary" @click="nextstep00">{{ $t("ctp.ok") }}</el-button>
              <el-button @click="sensitiveWordsDialogVisible = false">{{ $t("ctp.cancel") }}</el-button>
            </span>
          </div>
        </agc-dialog>

        <agc-dialog :title="$t('ctp.tips')" :visible.sync="saveOrder" width="420px">
          <div>
            <span>{{ $t("ctp.tips.confirmSaveOrder") }}</span>
          </div>
          <div class="save-order">
            <span slot="footer">
              <el-button type="primary" @click="saves">{{ $t("ctp.ok") }}</el-button>
              <el-button @click="handleBack">{{ $t("ctp.cancel") }}</el-button>
            </span>
          </div>
        </agc-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import { getConfigs } from '../../api/sp.js';
import {
  interactiveHaLog,
  interactiveHaLogLanguage
} from '../../util/interactiveHaLog.js';
import $ from 'jquery';
import { HELPLINK } from '../../constant/index.js';
export default {
  name: 'Order',
  data() {
    return {
      sensitiveWordsDialogVisible: false,
      sensitiveWordsMessage01: '',
      sensitiveWordsMessage02: '',
      sensitiveWordsMessage03: '',
      sensitiveWordsMessage04: '',
      sensitiveWordsMessage05: '',
      payingDialogVisible: false,
      savingDialogVisible: false,
      sourceLang: [],
      targetLang: [],
      currentTargetLang: [],
      screenShotFilename: '', // 上传屏幕截图文件名
      screenshotFilePath: '', // 上传屏幕截图文件
      sourceFilePath: '',
      platformUse: '', // 翻译结果是否供平台使用
      spread1: false,
      spread2: false,
      spread3: false,
      save00: false,
      taskId: '',
      application: false,
      showLanguage: false,
      showLanguage00: false,
      imgURL: './imgs/searchImg.png',
      searchList: [],
      search: '',
      agcUid: '',
      active: 0,
      lang01: false,
      shotscreenFiles: [],
      lan: [],
      num: 0,
      sourcefileTf: true,
      shotscreenFilesTf: true,
      repeatUse: '',
      screenShotsMessage: '',
      sourcefileMessage: '',
      appName: '',
      teamName: '',
      allowFileType: '',
      FileType: '',
      allowFileNums: '',
      allowScreenNums: '',
      allowScreenType: '',
      ScreenType: '',
      allowFileSize: '',
      allowScreenSize: '',
      isSave: false,
      zoneIdData: '',
      saveOrder: false,
      pageInfoNotSaved: false,
      backFlag: false,
      helpLink: HELPLINK,
      isPlatformUse: false,
      isRepeatUse: false,
      isSelect: false,
      isTargetLanguage: false,
      isClick: false,
      isDisable: false,
      rules: {
        currentSourceLang: [
          {
            required: true,
            message: this.$t('ctp.tips.chooseOriginLang'),
            trigger: ['blur', 'change']
          }
        ],
        chooseList: [
          {
            required: true
          }
        ],
        applicationName: [
          {
            required: true,
            message: this.$t('ctp.tips.appName'),
            trigger: ['blur', 'change']
          }
        ],
        appIntroduce: [
          {
            required: true,
            message: this.$t('ctp.AppIntroduct'),
            trigger: ['blur', 'change']
          }
        ],
        appIntroduceInshort: [
          {
            required: true,
            message: this.$t('ctp.tips.shortAppIntroduct'),
            trigger: ['blur', 'change']
          }
        ],
        sourceFilesInput: [
          {
            required: true,
            message: this.$t('ctp.tips.chooseAppDetail'),
            trigger: ['blur', 'change']
          }
        ]
      },
      ruleForm: {
        currentSourceLang: {},
        chooseList: [],
        applicationName: '',
        appIntroduce: '',
        appIntroduceInshort: '',
        appFeatures: '',
        sourceFiles: [],
        sourceFilesInput: ''
      },
      // 用来判断界面是否发生修改 start
      currentSourceLangString: '{}',
      chooseListString: '[]',
      applicationNameString: '',
      appIntroduceString: '',
      appIntroduceInshortString: '',
      appFeaturesString: '',
      sourceFilesString: '[]',
      sourceFilesInputString: '',
      spread1Value: false,
      spread2Value: false,
      repeatUseString: '',
      platformUseString: '',
      // 用来判断界面是否发生修改 end
      lang:
        localStorage.getItem('agc_lang') ||
        localStorage.getItem('agc_lang_site'), // 系统语言
    };
  },
  watch: {
    'ruleForm.currentSourceLang': function() {
      if (this.num !== 0) {
        const { lan } = this;
        let tf = false;
        lan.forEach(el => {
          if (
            Number(this.ruleForm.currentSourceLang.value) === 77 &&
            el.language === 'zh-CN'
          ) {
            tf = true;
            this.ruleForm.applicationName = el.appName ? el.appName : '';
            this.ruleForm.appIntroduce = el.appDesc ? el.appDesc : '';
            this.ruleForm.appIntroduceInshort = el.briefInfo
              ? el.briefInfo
              : '';
            this.ruleForm.appFeatures = el.newFeatures ? el.newFeatures : '';
          }
          if (
            Number(this.ruleForm.currentSourceLang.value) === 83 &&
            el.language === 'en-US'
          ) {
            tf = true;
            this.ruleForm.applicationName = el.appName ? el.appName : '';
            this.ruleForm.appIntroduce = el.appDesc ? el.appDesc : '';
            this.ruleForm.appIntroduceInshort = el.briefInfo
              ? el.briefInfo
              : '';
            this.ruleForm.appFeatures = el.newFeatures ? el.newFeatures : '';
          }
        });
        if (!tf) {
          this.ruleForm.applicationName = '';
          this.ruleForm.appIntroduce = '';
          this.ruleForm.appIntroduceInshort = '';
          this.ruleForm.appFeatures = '';
        }
      } else {
        this.num = 1;
      }
      const arr = [];
      this.ruleForm.chooseList.forEach(el => {
        if (el.value !== this.ruleForm.currentSourceLang.value) {
          arr.push(el);
        }
      });
      this.ruleForm.chooseList = arr;
      // 保存chooseList
      this.chooseListString = JSON.stringify(arr);
      this.applicationNameString = this.ruleForm.applicationName;
      this.appIntroduceString = this.ruleForm.appIntroduce;
      this.appIntroduceInshortString = this.ruleForm.appIntroduceInshort;
      this.appFeaturesString = this.ruleForm.appFeatures;
      this.appFeaturesString = this.ruleForm.appFeatures;
    },
    search() {
      this.searchList = [];
      for (const i in this.targetLang) {
        if (this.targetLang[i].name.indexOf(this.search) > -1) {
          this.searchList.push(this.targetLang[i]);
        } else if (!this.search) {
          this.searchList.push(this.targetLang[i]);
        }
      }
      const t = this;
      setTimeout(() => {
        // 筛选选中后改变选框样式
        $('.yh03').each(function() {
          $(this)
            .next()
            .prop('checked', false);
          for (const i in t.ruleForm.chooseList) {
            if (t.ruleForm.chooseList[i].value === $(this).text()) {
              $(this)
                .next()
                .prop('checked', true);
            }
          }
        });
        if (Object.keys(t.ruleForm.currentSourceLang).length) {
          if (t.ruleForm.chooseList.length === t.targetLang.length - 1) {
            t.$refs.checkAll.checked = true;
          } else {
            t.$refs.checkAll.checked = false;
          }
        } else if (!Object.keys(t.ruleForm.currentSourceLang).length) {
          if (t.ruleForm.chooseList.length === t.targetLang.length) {
            t.$refs.checkAll.checked = true;
          } else {
            t.$refs.checkAll.checked = false;
          }
        }
      }, 20);
    },
    platformUse() {
      this.isPlatformUse = this.platformUse === '';
      this.handleFlag();
    },
    repeatUse() {
      this.isRepeatUse = this.repeatUse === '';
      this.handleFlag();
    },
    spread1() {
      this.isSelect = !(this.spread1 || this.spread2);
      if (this.spread1) {
        this.ruleForm.sourceFilesInput = 'true';
      } else {
        this.ruleForm.sourceFilesInput = this.ruleForm.sourceFiles.length ? this.ruleForm.sourceFiles.length : '';
      }
      this.sourceFilesInputString = this.ruleForm.sourceFilesInput;

      this.handleFlag();
    },
    spread2() {
      this.isSelect = !(this.spread1 || this.spread2);
      this.handleFlag();
    },
    isSelect(nv) {
      if (nv) {
        this.pageInfoNotSaved = true;
        // 只有界面数据发生修改时，才需要提示保存弹框
        sessionStorage.setItem('savePromptFlag', 'Y');
      }
    },
    'ruleForm.chooseList': function() {
      if (this.isClick) {
        this.isTargetLanguage = this.ruleForm.chooseList.length === 0;
      }
    },
    ruleForm: {
      handler(nv, ov) {
        if (nv && ov) {
          // currentSourceLang改变
          const isCurLangModify = this.currentSourceLangString !== JSON.stringify(nv.currentSourceLang);
          // chooseList改变
          const isChooseListModify = this.chooseListString !== JSON.stringify(nv.chooseList);
          // applicationName改变
          const applicationNameModify = this.applicationNameString !== nv.applicationName;
          // appIntroduce改变
          const appIntroduceModify = this.appIntroduceString !== nv.appIntroduce;
          // appIntroduceInshort改变
          const appIntroduceInshortModify = this.appIntroduceInshortString !== nv.appIntroduceInshort;
          // appFeatures 改变
          const appFeaturesModify = this.appFeaturesString !== nv.appFeatures;
          // sourceFiles 改变
          const sourceFilesModify = this.sourceFilesString !== JSON.stringify(nv.sourceFiles);
          // sourceFilesInput 改变
          const sourceFilesInputModify = this.sourceFilesInputString !== nv.sourceFilesInput;
          // 其他基本类型的数据改变
          if (isCurLangModify || isChooseListModify || applicationNameModify
            || appIntroduceModify || appIntroduceInshortModify || appFeaturesModify
            || sourceFilesModify || sourceFilesInputModify || JSON.stringify(nv) !== JSON.stringify(ov)) {
            this.pageInfoNotSaved = true;
            // 只有界面数据发生修改时，才需要提示保存弹框
            sessionStorage.setItem('savePromptFlag', 'Y');
          }
        }
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    this.zoneIdData = sessionStorage.getItem('tszoneId');
    // 添加监听事件
    window.addEventListener('message', this.handleSaveConfirm);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('message', this.handleSaveConfirm);
    });
    const { packageType } = JSON.parse(sessionStorage.getItem('appInfo'));
    if (packageType === 7 || packageType === 8) {
      AGC.callServiceAPI(
        `amis/app-manage/v1/app?returnLatestVersion=1&${
          JSON.parse(sessionStorage.getItem('appInfo')).appID
        }`,
        { header: {}, body: '' },
        'GET',
        null,
        3000,
        res => {
          this.lan = res.versionInfo.languages;
          this.teamName = res.appInfo.developerInfo.devName
            ? res.appInfo.developerInfo.devName
            : '';
        }
      );
    } else {
      AGC.callServiceAPI(
        `cds/app-distirbution/v1/all/app/${
          JSON.parse(sessionStorage.getItem('appInfo')).appID
        }`,
        { header: {}, body: '' },
        'GET',
        null,
        3000,
        res => {
          this.lan = res.appInfo.languages;
          this.teamName = JSON.parse(res.appInfo['contactInfo']).devName
            ? JSON.parse(res.appInfo['contactInfo']).devName
            : '';
        }
      );
    }
    this.getLanguage();
  },
  methods: {
    // 处理是否展示保存弹框提示
    handleFlag() {
      if (this.spread1 !== this.spread1Value || this.spread2 !== this.spread2Value
        || this.platformUseString !== this.platformUse || this.repeatUseString !== this.repeatUse) {
        this.pageInfoNotSaved = true;
        // 只有界面数据发生修改时，才需要提示保存弹框
        sessionStorage.setItem('savePromptFlag', 'Y');
      }
    },
    getConfig() {
      getConfigs('CP')
        .then(res => {
          if (res.ret.code === 0) {
            const language =
              localStorage.getItem('agc_lang') ||
              localStorage.getItem('agc_lang_site');
            res.data.forEach(el => {
              if (el.ruleName === 'TranslationReq.allowFileType') {
                this.allowFileType = el.ruleValue;
                if (language === 'cn') {
                  this.FileType = el.ruleValue.replace(/,/g, '、');
                } else {
                  this.FileType = el.ruleValue.replace(/,/g, ', ');
                }
              }
              if (el.ruleName === 'TranslationReq.allowFileNums') {
                this.allowFileNums = el.ruleValue;
              }
              if (el.ruleName === 'AttachFile.allowFileNums') {
                this.allowScreenNums = el.ruleValue;
              }
              if (el.ruleName === 'AttachFile.allowFileType') {
                this.allowScreenType = el.ruleValue;
                if (language === 'cn') {
                  this.ScreenType = el.ruleValue.replace(/,/g, '、');
                } else {
                  this.ScreenType = el.ruleValue.replace(/,/g, ', ');
                }
              }
              if (el.ruleName === 'TranslationReq.allowFileSize') {
                this.allowFileSize = el.ruleValue;
              }
              if (el.ruleName === 'AttachFile.allowFileSize') {
                this.allowScreenSize = el.ruleValue;
              }
            });
          } else {
            this.$promptMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.tips')
            );
          }
        })
        .catch(err => {
          this.$promptMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.tips')
          );
          return err;
        });
    },
    // 下载翻译内容模板 
    storageDownLoadFile(path) {
      if (path && path.trim().length > 0) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storageRef.child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    handleSaveConfirm(e) {
      const { data } = e;
      if (data.command !== 'save') {
        return;
      }
      if (data.command === 'save' && data.clickInfo.updateCatalog) {
        window.top.postMessage(
          {
            functionName: 'handleIframeSaveMessage',
            params: [data.clickInfo]
          },
          window.top.location.origin
        );
      } else if (data.command === 'save' && !data.clickInfo.updateCatalog) {
        this.$confirmMsg(
          this.$t('ctp.tips.confirmSaveOrder'),
          this.$t('ctp.saveOrder'),
          {
            callback: action => {
              if (action === 'confirm') {
                if (!this.sourcefileTf || !this.shotscreenFilesTf) {
                  this.save00 = false;
                  this.$alertMsg(
                    this.$t('ctp.uploadFileWaiting'),
                    this.$t('ctp.warning')
                  );
                  return;
                }
                this.save00 = true;
                this.skipnext(true).then(() => {
                  sessionStorage.setItem('savePromptFlag', 'N');
                  window.top.postMessage(
                    {
                      functionName: 'handleIframeSaveMessage',
                      params: [data.clickInfo]
                    },
                    window.top.location.origin
                  );
                });
              }
              if (action === 'cancel') {
                sessionStorage.setItem('savePromptFlag', 'N');
                window.top.postMessage(
                  {
                    functionName: 'handleIframeSaveMessage',
                    params: [data.clickInfo]
                  },
                  window.top.location.origin
                );
              }
            }
          }
        );
      }
    },
    chooselan() {
      this.isClick = true;
      this.search = '';
      this.lang01 = true;
      const t = this;
      this.$nextTick(() => {
        $('.yh03').each(function() {
          $(this)
            .next()
            .prop('checked', false);
          for (const i in t.ruleForm.chooseList) {
            if (t.ruleForm.chooseList[i].value === $(this).text()) {
              $(this)
                .next()
                .prop('checked', true);
            }
          }
        });
        if (Object.keys(t.ruleForm.currentSourceLang).length) {
          if (t.ruleForm.chooseList.length === t.targetLang.length - 1) {
            t.$refs.checkAll.checked = true;
          } else {
            t.$refs.checkAll.checked = false;
          }
        } else if (!Object.keys(t.ruleForm.currentSourceLang).length) {
          if (t.ruleForm.chooseList.length === t.targetLang.length) {
            t.$refs.checkAll.checked = true;
          } else {
            t.$refs.checkAll.checked = false;
          }
        }
      });
    },
    add(e, v) {
      if ($(e.target).prop('checked')) {
        for (const i in this.targetLang) {
          if (v === this.targetLang[i].value) {
            this.ruleForm.chooseList.push(this.targetLang[i]);
          }
        }
        this.ruleForm.chooseList = Array.from(
          new Set(this.ruleForm.chooseList)
        );
        this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
          a.name.localeCompare(b.name, 'zh-Hans-CN', { sensitivity: 'accent' })
        );
        if (this.ruleForm.chooseList.length === this.targetLang.length - 1) {
          $(e.target)
            .parent()
            .siblings()
            .eq(0)
            .children()
            .eq(1)
            .prop('checked', true);
        }
      } else {
        const aa = [];
        for (const i in this.ruleForm.chooseList) {
          if (v !== this.ruleForm.chooseList[i].value) {
            aa.push(this.ruleForm.chooseList[i]);
          }
        }
        this.ruleForm.chooseList = aa;
        this.ruleForm.chooseList = Array.from(
          new Set(this.ruleForm.chooseList)
        );
        this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
          a.name.localeCompare(b.name, 'zh-Hans-CN', { sensitivity: 'accent' })
        );
        $(e.target)
          .parent()
          .siblings()
          .eq(0)
          .children()
          .eq(1)
          .prop('checked', false);
      }
    },
    addCheck(e, v) {
      if (
        !$(e.target)
          .prev()
          .prop('checked')
      ) {
        $(e.target)
          .prev()
          .prop('checked', true);
        for (const i in this.targetLang) {
          if (v === this.targetLang[i].value) {
            this.ruleForm.chooseList.push(this.targetLang[i]);
          }
        }
        this.ruleForm.chooseList = Array.from(
          new Set(this.ruleForm.chooseList)
        );
        this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
          a.name.localeCompare(b.name, 'zh-Hans-CN', { sensitivity: 'accent' })
        );
        if (this.ruleForm.chooseList.length === this.targetLang.length - 1) {
          $(e.target)
            .parent()
            .siblings()
            .eq(0)
            .children()
            .eq(1)
            .prop('checked', true);
        }
      } else {
        const aa = [];
        for (const i in this.ruleForm.chooseList) {
          if (v !== this.ruleForm.chooseList[i].value) {
            aa.push(this.ruleForm.chooseList[i]);
          }
        }
        this.ruleForm.chooseList = aa;
        this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
          a.name.localeCompare(b.name, 'zh-Hans-CN', { sensitivity: 'accent' })
        );
        $(e.target)
          .prev()
          .prop('checked', false);
        if (this.ruleForm.chooseList.length !== this.targetLang.length - 1) {
          $(e.target)
            .parent()
            .siblings()
            .eq(0)
            .children()
            .eq(1)
            .prop('checked', false);
        }
      }
    },
    addAll(e) {
      this.ruleForm.chooseList = [];
      if (!this.search) {
        if ($(e.target).prop('checked')) {
          for (const i in this.targetLang) {
            if (
              this.targetLang[i].value !== this.ruleForm.currentSourceLang.value
            ) {
              this.ruleForm.chooseList.push(this.targetLang[i]);
            }
          }
          this.ruleForm.chooseList = Array.from(
            new Set(this.ruleForm.chooseList)
          );
          this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
            a.name.localeCompare(b.name, 'zh-Hans-CN', {
              sensitivity: 'accent'
            })
          );
          $('.yh02').each(function() {
            $(this).prop('checked', true);
          });
        } else {
          $('.yh02').each(function() {
            $(this).prop('checked', false);
          });
        }
      } else if (this.search) {
        if ($(e.target).prop('checked')) {
          for (const i in this.searchList) {
            if (
              this.searchList[i].value !== this.ruleForm.currentSourceLang.value
            ) {
              this.ruleForm.chooseList.push(this.searchList[i]);
            }
          }
          this.ruleForm.chooseList = Array.from(
            new Set(this.ruleForm.chooseList)
          );
          this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
            a.name.localeCompare(b.name, 'zh-Hans-CN', {
              sensitivity: 'accent'
            })
          );
          $('.yh02').each(function() {
            $(this).prop('checked', true);
          });
        } else {
          const newlangARR = [];
          this.searchList.forEach(el => {
            this.ruleForm.chooseList.forEach(vl => {
              if (el.value !== vl.value) {
                newlangARR.push(vl);
              }
            });
          });
          this.ruleForm.chooseList = newlangARR;
          this.ruleForm.chooseList = Array.from(
            new Set(this.ruleForm.chooseList)
          );
          this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
            a.name.localeCompare(b.name, 'zh-Hans-CN', {
              sensitivity: 'accent'
            })
          );
          $('.yh02').each(function() {
            $(this).prop('checked', false);
          });
        }
      }
    },
    addCheckAll(e) {
      if (
        !$(e.target)
          .prev()
          .prop('checked')
      ) {
        $(e.target)
          .prev()
          .prop('checked', true);
        if (this.search) {
          for (const i in this.searchList) {
            if (
              this.searchList[i].value !== this.ruleForm.currentSourceLang.value
            ) {
              this.ruleForm.chooseList.push(this.searchList[i]);
            }
          }
          $(e.target)
            .parent()
            .siblings()
            .find('.language-checkbox')
            .prop('checked', true);
          this.ruleForm.chooseList = Array.from(
            new Set(this.ruleForm.chooseList)
          );
          this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
            a.name.localeCompare(b.name, 'zh-Hans-CN', {
              sensitivity: 'accent'
            })
          );
        } else {
          for (const i in this.targetLang) {
            if (
              this.targetLang[i].value !== this.ruleForm.currentSourceLang.value
            ) {
              this.ruleForm.chooseList.push(this.targetLang[i]);
            }
          }
          this.ruleForm.chooseList = Array.from(
            new Set(this.ruleForm.chooseList)
          );
          this.ruleForm.chooseList = this.ruleForm.chooseList.sort((a, b) =>
            a.name.localeCompare(b.name, 'zh-Hans-CN', {
              sensitivity: 'accent'
            })
          );
          $(e.target)
            .parent()
            .siblings()
            .find('.language-checkbox')
            .prop('checked', true);
        }
      } else {
        $(e.target)
          .prev()
          .prop('checked', false);
        this.ruleForm.chooseList = [];
        $(e.target)
          .parent()
          .siblings()
          .find('.language-checkbox')
          .prop('checked', false);
      }
    },
    deleteTar(v) {
      const aa = [];
      for (const i in this.ruleForm.chooseList) {
        if (v !== this.ruleForm.chooseList[i].value) {
          aa.push(this.ruleForm.chooseList[i]);
        }
      }
      $('.yh03').each(function() {
        if ($(this).text() === v) {
          $(this)
            .next()
            .prop('checked', false);
        }
      });
      this.ruleForm.chooseList = aa;
      this.$refs.checkAll.checked = false;
    },
    deleteAll() {
      this.ruleForm.chooseList = [];
      $('.yh02').each(function() {
        $(this).prop('checked', false);
      });
    },
    saves() {
      if (!this.sourcefileTf || !this.shotscreenFilesTf) {
        this.save00 = false;
        this.$alertMsg(
          this.$t('ctp.uploadFileWaiting'),
          this.$t('ctp.warning')
        );
        return;
      }
      try {
        if (
          Object.keys(this.ruleForm.currentSourceLang).length &&
          this.ruleForm.chooseList.length
        ) {
          const langList = [];
          this.ruleForm.chooseList.forEach(item => {
            langList.push(item.name);
          });
          interactiveHaLogLanguage({
            title: '翻译服务CP--保存',
            tagType: 'TranslationService',
            Data: {
              originLanguage: this.ruleForm.currentSourceLang.name,
              targetLanguage: langList
            }
          });
        }
      } catch (error) {
        return error;
      }
      this.save00 = true;
      this.skipnext();
    },
    infoVerification() {
      this.$refs['ruleForm'].validate(valid => {
        this.isClick = true;
        if (valid) {
          if (this.isTargetLanguage) {
            this.save00 = false;
            return;
          }
          if (!(this.spread1 || this.spread2)) {
            this.isSelect = true;
            this.save00 = false;
            return;
          }
          if (this.repeatUse === '') {
            this.save00 = false;
            this.isRepeatUse = true;
            return;
          }
          if (this.platformUse === '') {
            this.save00 = false;
            this.isPlatformUse = true;
            return;
          }
          if (!this.sourcefileTf && this.spread2) {
            this.save00 = false;
            this.$alertMsg(
              this.$t('ctp.uploadFileWaiting'),
              this.$t('ctp.warning')
            );
            return;
          }
          if (!this.shotscreenFilesTf) {
            this.save00 = false;
            this.$alertMsg(
              this.$t('ctp.uploadScreenshotWaiting'),
              this.$t('ctp.warning')
            );
            return;
          }
          this.isSave = true;
          return;
        }
        this.isPlatformUse = this.platformUse === '';
        this.isRepeatUse = this.repeatUse === '';
        this.isSelect = !(this.spread1 || this.spread2);
        this.isTargetLanguage = this.ruleForm.chooseList.length === 0;
        this.$nextTick(() => {
          const isError = document.getElementsByClassName('is-error');
          const focusInput = isError[0].querySelector('.error-div') || isError[0].querySelector('input');
          focusInput.focus();
        });
      });
    },
    skipnext() {
      if (!this.save00) {
        this.infoVerification();
        if (this.isSave) {
          this.saveTask();
        }
      } else {
        this.saveOrder = false;
        this.saveTask();
      }
    },
    saveTask() {
      const data = {};
      const arr = [];
      const arr00 = [];
      this.ruleForm.chooseList.forEach(el => {
        arr.push(el.value);
        arr00.push(`${el.name}-${el.locale}`);
      });
      if (this.taskId) {
        data.taskId = this.taskId;
      }
      data.application = this.spread1 ? this.spread1 : 'false';
      if (this.spread2) {
        const sourceFileName = [];
        const sourceFilePath = [];
        this.ruleForm.sourceFiles.forEach(el => {
          sourceFileName.push(el.name);
          sourceFilePath.push(el.url);
        });
        data.sourceFilePath = sourceFilePath.join(',');
        data.sourceFileName = sourceFileName.join(',');
      } else {
        data.sourceFilePath = '';
        data.sourceFileName = '';
      }

      // 图片
      if (this.spread2) {
        const screenshotFileName = [];
        const screenshotFilePath = [];
        this.shotscreenFiles.forEach(el => {
          screenshotFileName.push(el.name);
          screenshotFilePath.push(el.url);
        });
        data.screenshotFileName = screenshotFileName.join(',');
        data.screenshotFilePath = screenshotFilePath.join(',');
      } else {
        data.screenshotFileName = '';
        data.screenshotFilePath = '';
      }
      // 语言
      for (const i in this.sourceLang) {
        if (
          this.sourceLang[i].value === this.ruleForm.currentSourceLang.value
        ) {
          data.sourceLanguage = `${this.sourceLang[i].name}-${this.sourceLang[i].locale}`;
        }
      }
      if (!this.ruleForm.currentSourceLang.value) {
        data.sourceLanguage = '';
      }
      data.sourceLanguageId = this.ruleForm.currentSourceLang.value
        ? this.ruleForm.currentSourceLang.value
        : '';
      data.targetLanguageId = arr.join(',');
      data.targetLanguage = arr00.join(',');
      // 应用
      if (this.spread1) {
        data.applicationName = this.ruleForm.applicationName;
        data.appIntroduce = this.ruleForm.appIntroduce;
        data.appIntroduceInshort = this.ruleForm.appIntroduceInshort;
        data.appFeatures = this.ruleForm.appFeatures;
      } else {
        data.applicationName = '';
        data.appIntroduce = '';
        data.appIntroduceInshort = '';
        data.appFeatures = '';
      }
      data.platformUse = this.platformUse ? this.platformUse : 'false';
      data.repeatUse = this.repeatUse ? this.repeatUse : 'false';
      data.teamName = this.teamName;

      data.uid = Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
      const appInfo = AGC.getAppInfo();
      if (appInfo) {
        data.appId = JSON.parse(appInfo).appID;
        data.appName = JSON.parse(appInfo).appName;
      }
      if (this.save00) {
        this.savingDialogVisible = true;
      } else {
        this.payingDialogVisible = true;
      }
      const url = 'translation/v1/cp/orderAndTask';
      let returnFlag = true;
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { header: {}, body: data },
          'POST',
          this.zoneIdData,
          6000,
          res00 => {
            if (res00.ret.code === 0) {
              const ddTaskId = res00.taskId;

              /**
               * 只要创建订单成功就给当前taskId赋值，即使下面调用失败，再次点击下一步的时候由于有taskId，
               * 不会创建新的订单，只会修改原订单
               */
              this.taskId = ddTaskId;
              sessionStorage.setItem('agc_translate_skip_taskId', ddTaskId);
              // 检查内容是否包含敏感词
              if (!this.save00) {
                AGC.callServiceAPI(
                  'translation/v1/checkTranslation',
                  {
                    header: {},
                    body: {
                      taskId: ddTaskId
                    }
                  },
                  'POST',
                  this.zoneIdData,
                  30000,
                  res => {
                    this.payingDialogVisible = false;
                    this.savingDialogVisible = false;
                    if (res.ret.code === 0) {
                      // 包含敏感词页面弹出提示框，不跳转页面
                      let tf = false;
                      this.sensitiveWordsMessage01 = '';
                      this.sensitiveWordsMessage02 = '';
                      this.sensitiveWordsMessage03 = '';
                      this.sensitiveWordsMessage04 = '';
                      if (
                        res.sensitiveWords.applicationName &&
                        res.sensitiveWords.applicationName.length > 0
                      ) {
                        this.sensitiveWordsMessage01 =
                          res.sensitiveWords.applicationName;
                        tf = true;
                      }
                      if (
                        res.sensitiveWords.appIntroduce &&
                        res.sensitiveWords.appIntroduce.length > 0
                      ) {
                        this.sensitiveWordsMessage02 =
                          res.sensitiveWords.appIntroduce;
                        tf = true;
                      }
                      if (
                        res.sensitiveWords.appIntroduceInshort &&
                        res.sensitiveWords.appIntroduceInshort.length > 0
                      ) {
                        this.sensitiveWordsMessage03 =
                          res.sensitiveWords.appIntroduceInshort;
                        tf = true;
                      }
                      if (
                        res.sensitiveWords.appFeatures &&
                        res.sensitiveWords.appFeatures.length > 0
                      ) {
                        this.sensitiveWordsMessage05 =
                          res.sensitiveWords.appFeatures;
                        tf = true;
                      }
                      if (
                        res.sensitiveWords.fileContent &&
                        res.sensitiveWords.fileContent.length > 0
                      ) {
                        this.sensitiveWordsMessage04 =
                          res.sensitiveWords.fileContent;
                        tf = true;
                      }

                      if (tf) {
                        this.taskId = ddTaskId;
                        this.sensitiveWordsDialogVisible = true;
                      } else {
                        sessionStorage.removeItem('agc_translate_skip_taskId');
                        if (this.save00) {
                          this.$router.push({ path: '/cp/list' });
                        } else {
                          interactiveHaLog({
                            title: '翻译服务CP--下一步',
                            tagType: 'TranslationService',
                            translationServiceId: ''
                          });
                          sessionStorage.setItem(
                            'agc_translate_skip_taskId',
                            ddTaskId
                          );
                          this.$router.push({ path: '/cp/cfm_order' });
                        }
                      }
                      sessionStorage.setItem('savePromptFlag', 'N');
                    } else {
                      this.save00 = false;
                      returnFlag = false;
                      this.$alertMsg(
                        `${this.$t('ctp.tips.payError.abnormal')}(${
                          res.code ? res.code : res.ret.code
                        })`,
                        this.$t('ctp.warning')
                      );
                    }
                    this.isSave = false;
                  },
                  err => {
                    this.isSave = false;
                    this.save00 = false;
                    returnFlag = false;
                    this.payingDialogVisible = false;
                    this.savingDialogVisible = false;
                    this.$alertMsg(
                      this.$t('ctp.error.systemAbnormal'),
                      this.$t('ctp.warning')
                    );
                    return err;
                  }
                );
              } else {
                this.savingDialogVisible = false;
                this.$promptMsg(
                  this.$t('ctp.tips.saveSuccess'),
                  this.$t('ctp.tips'),
                  {
                    callback: action => {
                      if (action) {
                        sessionStorage.removeItem('agc_translate_skip_taskId');
                        this.$router.push({ path: '/cp/list' });
                        sessionStorage.setItem('savePromptFlag', 'N');
                      }
                    }
                  }
                );
              }
            } else if (
              (res00.ret.code === 141940557330 &&
                res00.ret.msg.indexOf('TranslateTask data not found')) ||
              (res00.ret.code === 1 && res00.ret.msg.indexOf('cannot be found'))
            ) {
              this.save00 = false;
              this.payingDialogVisible = false;
              this.savingDialogVisible = false;
              this.$alertMsg(
                `${this.$t('ctp.tips.alreadyDelete')}(${
                  res00.code ? res00.code : res00.ret.code
                })`,
                this.$t('ctp.warning')
              );
              returnFlag = false;
            } else if (res00.ret.code === 141940557346) {
              this.save00 = false;
              this.payingDialogVisible = false;
              this.savingDialogVisible = false;
              this.$alertMsg(
                `${this.$t('ctp.tips.cp.typeError')}(${
                  res00.code ? res00.code : res00.ret.code
                })`,
                this.$t('ctp.warning')
              );
              returnFlag = false;
            } else if (res00.ret.code === 141940557345) {
              this.save00 = false;
              this.payingDialogVisible = false;
              this.savingDialogVisible = false;
              this.$alertMsg(
                this.$t('ctp.tips.createTaskLimit'),
                this.$t('ctp.warning')
              );
              returnFlag = false;
            } else if (res00.ret.code === 141940557347) {
              this.save00 = false;
              this.payingDialogVisible = false;
              this.savingDialogVisible = false;
              this.$alertMsg(
                this.$t('ctp.tips.totalCreateTaskLimit'),
                this.$t('ctp.warning')
              );
              returnFlag = false;
            } else {
              this.save00 = false;
              this.payingDialogVisible = false;
              this.savingDialogVisible = false;
              this.$alertMsg(
                `${this.$t('ctp.tips.payError.abnormal')}(${
                  res00.code ? res00.code : res00.ret.code
                })`,
                this.$t('ctp.warning')
              );
              returnFlag = false;
            }
            this.isSave = false;
            if (returnFlag) {
              resolve({ status: 'success' });
            } else {
              reject({ status: 'error' });
            }
          },
          () => {
            this.isSave = false;
            this.save00 = false;
            this.payingDialogVisible = false;
            this.savingDialogVisible = false;
            this.$alertMsg(
              this.$t('ctp.error.systemAbnormal'),
              this.$t('ctp.warning')
            );
            returnFlag = false;
            if (returnFlag) {
              resolve({ status: 'success' });
            } else {
              reject({ status: 'error' });
            }
          }
        );
      });
    },
    nextstep00() {
      this.sensitiveWordsDialogVisible = false;
      if (this.save00) {
        sessionStorage.removeItem('agc_translate_skip_taskId');
        this.$router.push({ path: '/cp/list' });
      } else {
        sessionStorage.removeItem('agc_translate_skip_taskId');
        sessionStorage.setItem('agc_translate_skip_taskId', this.taskId);
        this.$router.push({ path: '/cp/cfm_order' });
      }
    },
    cancel() {
      interactiveHaLog({
        title: '翻译服务CP--取消',
        tagType: 'TranslationService',
        translationServiceId: ''
      });
      sessionStorage.setItem('savePromptFlag', 'N');
      this.$router.push({ path: '/cp/list' });
    },
    handleExceed() {
      this.$alertMsg(this.$t('ctp.tips.fileNum'), this.$t('ctp.warning'));
    },
    sourcefile(file, fileList) {
      if (this.sourcefileTf) {
        const originalName = file.name;
        const suffix = originalName.split('.').pop();
        if (Math.ceil(file.size / 1024) > this.allowFileSize * 1024) {
          this.$alertMsg(
            this.$t('ctp.tips.fileSize', { size: this.allowFileSize }),
            this.$t('ctp.warning')
          );
          fileList.pop();
          return;
        }
        if (this.allowFileType.search(suffix.toLowerCase()) > -1) {
          this.$refs.uploadingLocalFile.$children[1].$el.title = originalName;
          AGC.callServiceAPI(
            'translation/v1/storagePath',
            {},
            'POST',
            this.zoneIdData,
            3000,
            res => {
              this.isDisable = true;
              if (res.ret.code === 0) {
                const storage = this.$agc.storage();
                const storageRef = storage.storageReference();
                const path = res.data + originalName;
                this.sourcefileTf = false;
                this.sourcefileMessage = this.$t('ctp.tips.fileUploading');
                const uploadTask = storageRef.child(path).put(file.raw);
                uploadTask.on(
                  'state_changed',
                  snapshot => {
                    if (!snapshot) {
                      return;
                    }
                    if (Number(snapshot.totalByteCount) === 0) {
                      return;
                    }
                    switch (snapshot.state) {
                    case 'paused':
                      break;
                    case 'running':
                      break;
                    case 'success':
                      break;
                    case 'canceled':
                      break;
                    case 'error':
                      break;
                    }
                  },
                  (error) => {
                    this.sourcefileTf = true;
                    this.isDisable = false;
                    this.sourcefileMessage = '';
                    this.ruleForm.sourceFilesInput = '';
                    this.$alertMsg(
                      this.$t('ctp.error.fileUploadFail'),
                      this.$t('ctp.warning')
                    );
                    fileList.pop();
                    return error;
                  },
                  () => {
                    this.ruleForm.sourceFiles = fileList;
                    this.sourceFilesString = fileList;
                    file.url = `${this.$agc.DEFAULT_BUCKET}:${path}`;
                    this.sourcefileTf = true;
                    this.sourcefileMessage = '';
                    this.ruleForm.sourceFilesInput = this.ruleForm.sourceFiles.length;
                    this.isDisable = false;
                  }
                );
                uploadTask.catch(err => {
                  this.sourcefileTf = true;
                  this.sourcefileMessage = '';
                  this.isDisable = false;
                  this.ruleForm.sourceFilesInput = '';
                  this.$alertMsg(
                    this.$t('ctp.error.fileUploadFail'),
                    this.$t('ctp.warning')
                  );
                  fileList.pop();
                  return err;
                });
              } else {
                this.isDisable = false;
                this.$alertMsg(
                  this.$t('ctp.error.fileUploadFail'),
                  this.$t('ctp.warning')
                );
                fileList.pop();
              }
            },
            err => {
              this.isDisable = false;
              this.$alertMsg(
                this.$t('ctp.error.fileUploadFail'),
                this.$t('ctp.warning')
              );
              fileList.pop();
              return err;
            }
          );
        } else {
          this.$alertMsg(
            this.$t('ctp.tips.manuscriptType', { type: this.allowFileType }),
            this.$t('ctp.warning')
          );
          fileList.pop();
        }
      } else {
        this.$alertMsg(
          this.$t('ctp.uploadFileWaiting'),
          this.$t('ctp.warning')
        );
        fileList.pop();
      }
    },
    handlePreviewSource(file) {
      if (file['url']) {
        const storage = this.$agc.storage();
        const path = file.url;
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storage.storageReference().child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    removeSourceFile(file, fileList) {
      if (file['url']) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const cloudStoragePath = file.url.substring(file.url.indexOf(':') + 1);
        storageRef
          .child(cloudStoragePath)
          .delete()
          .then(() => {
            this.sourcefileTf = true;
            this.ruleForm.sourceFiles = fileList;
            this.sourceFilesString = fileList;
            this.ruleForm.sourceFilesInput = '';
          })
          .catch(() => {
            this.sourcefileTf = true;
            this.$promptMsg(
              this.$t('ctp.error.deleteFileFail'),
              this.$t('ctp.tips')
            );
          });
      } else {
        this.ruleForm.sourceFiles = fileList;
        this.sourceFilesString = fileList;
        this.sourcefileTf = true;
      }
      this.sourcefileMessage = '';
    },

    screenShots(file, fileList) {
      if (this.shotscreenFilesTf) {
        const originalName = file.name;
        const suffix = originalName.split('.').pop();
        const suffixName = originalName.substring(
          0,
          originalName.lastIndexOf('.')
        );
        if (suffixName.length > 90) {
          this.$alertMsg(
            this.$t('ctp.tips.fileSizeTips'),
            this.$t('ctp.warning')
          );
          fileList.pop();
          return false;
        }
        if (Math.ceil(file.size / 1024) > this.allowScreenSize * 1024) {
          this.$alertMsg(
            this.$t('ctp.tips.screenFileSize', { size: this.allowScreenSize }),
            this.$t('ctp.warning')
          );
          fileList.pop();
          return;
        }
        if (this.allowScreenType.search(suffix.toUpperCase()) > -1) {
          this.$refs.screenshotFilePath.$children[1].$el.title = originalName;
          AGC.callServiceAPI(
            'translation/v1/storagePath',
            {},
            'POST',
            this.zoneIdData,
            3000,
            res => {
              if (res.ret.code === 0) {
                const storage = this.$agc.storage();
                const storageRef = storage.storageReference();
                const path = res.data + originalName;
                this.shotscreenFilesTf = false;
                this.screenShotsMessage = this.$t('ctp.tips.imageUploading');
                const uploadTask = storageRef.child(path).put(file.raw);
                this.screenShotFilename = '';
                this.screenshotFilePath = '';
                uploadTask.on(
                  'state_changed',
                  snapshot => {
                    if (!snapshot) {
                      return;
                    }
                    if (Number(snapshot.totalByteCount) === 0) {
                      return;
                    }
                    switch (snapshot.state) {
                    case 'paused':
                      break;
                    case 'running':
                      break;
                    case 'success':
                      break;
                    case 'canceled':
                      break;
                    case 'error':
                      break;
                    }
                  },
                  (error) => {
                    this.$alertMsg(
                      this.$t('ctp.error.fileUploadFail'),
                      this.$t('ctp.warning')
                    );
                    this.screenShotsMessage = '';
                    this.shotscreenFilesTf = true;
                    fileList.pop();
                    return error;
                  },
                  () => {
                    file.url = `${this.$agc.DEFAULT_BUCKET}:${path}`;
                    this.shotscreenFiles = fileList;
                    this.shotscreenFilesTf = true;
                    this.screenShotsMessage = '';
                  }
                );
                uploadTask.catch(err => {
                  this.$alertMsg(
                    this.$t('ctp.error.fileUploadFail'),
                    this.$t('ctp.warning')
                  );
                  this.screenShotsMessage = '';
                  this.shotscreenFilesTf = true;
                  fileList.pop();
                  return err;
                });
              } else {
                this.$alertMsg(
                  this.$t('ctp.error.fileUploadFail'),
                  this.$t('ctp.warning')
                );
                this.screenShotsMessage = '';
                this.shotscreenFilesTf = true;
                fileList.pop();
              }
            },
            err => {
              this.$alertMsg(
                this.$t('ctp.error.fileUploadFail'),
                this.$t('ctp.warning')
              );
              this.screenShotsMessage = '';
              fileList.pop();
              return err;
            }
          );
        } else {
          this.$alertMsg(
            this.$t('ctp.tips.imageType', { type: this.allowScreenType }),
            this.$t('ctp.warning')
          );
          fileList.pop();
        }
      } else {
        this.$alertMsg(
          this.$t('ctp.uploadScreenshotWaiting'),
          this.$t('ctp.warning')
        );
        fileList.pop();
      }
    },
    handlePreviewScreen(file) {
      if (file['url']) {
        const storage = this.$agc.storage();
        const path = file.url;
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storage.storageReference().child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    onRemoveScreenFile(file, fileList) {
      if (file['url']) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const cloudStoragePath = file.url.substring(file.url.indexOf(':') + 1);
        storageRef
          .child(cloudStoragePath)
          .delete()
          .then(() => {
            this.shotscreenFiles = fileList;
            this.shotscreenFilesTf = true;
            this.screenShotsMessage = '';
          })
          .catch(() => {
            this.shotscreenFilesTf = true;
            this.screenShotsMessage = '';
            this.$promptMsg(
              this.$t('ctp.error.deleteFileFail'),
              this.$t('ctp.tips')
            );
          });
      } else {
        this.shotscreenFiles = fileList;
        this.shotscreenFilesTf = true;
        this.screenShotsMessage = '';
      }
    },
    getLanguage() {
      const data = {};
      data.uid = Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
      const url = 'translation/v1/cp/getLanguages';
      AGC.callServiceAPI(
        url,
        { header: {}, body: data },
        'POST',
        this.zoneIdData,
        3000,
        res => {
          const language =
            localStorage.getItem('agc_lang') ||
            localStorage.getItem('agc_lang_site');
          if (res.ret.code === 0) {
            this.getConfig();
            const array = [];
            this.sourceLang = [];
            res.data.forEach(el => {
              if (el.translateSupport === 'Y') {
                if (language === 'cn') {
                  this.sourceLang.push({
                    value: el.languageId,
                    name: el.languageNameCh,
                    locale: el.languageAbridge
                  });
                } else if (language === 'en') {
                  this.sourceLang.push({
                    value: el.languageId,
                    name: el.languageNameEn,
                    locale: el.languageAbridge
                  });
                } else if (language === 'ru') {
                  this.sourceLang.push({
                    value: el.languageId,
                    name: el.languageNameRu,
                    locale: el.languageAbridge
                  });
                }
              }
              if (language === 'cn') {
                array.push({
                  value: el.languageId,
                  name: el.languageNameCh,
                  locale: el.languageAbridge
                });
              } else if (language === 'en') {
                array.push({
                  value: el.languageId,
                  name: el.languageNameEn,
                  locale: el.languageAbridge
                });
              } else if (language === 'ru') {
                array.push({
                  value: el.languageId,
                  name: el.languageNameRu,
                  locale: el.languageAbridge
                });
              }
            });
            array.sort((a, b) =>
              a.name.localeCompare(b.name, 'zh-Hans-CN', {
                sensitivity: 'accent'
              })
            );
            this.targetLang = array;
            this.searchList = array;
            this.sourceLang.forEach(el => {
              if (el.value === 77) {
                this.ruleForm.currentSourceLang = el;
                // 保存接口返回的currentSourceLang
                this.currentSourceLangString = JSON.stringify(el);
              }
            });
            const taskId = sessionStorage.getItem('agc_translate_skip_taskId');

            if (taskId && taskId !== undefined) {
              this.taskId = taskId;
              const { sourceLang } = this;
              const { targetLang } = this;
              const data = {};
              data.query = 'query00';
              data.taskId = taskId;
              const url = 'translation/v1/cp/orderAndTask';
              let res = '';
              AGC.callServiceAPI(
                url,
                { header: {}, body: data },
                'POST',
                this.zoneIdData,
                6000,
                res00 => {
                  if (res00.ret.code === 0) {
                    res = res00.data;
                    this.spread1 = false;

                    for (const i in sourceLang) {
                      if (sourceLang[i].value === res.sourceLanguageId) {
                        this.ruleForm.currentSourceLang = sourceLang[i];
                        // 保存currentSourceLang
                        this.currentSourceLangString = JSON.stringify(sourceLang[i]);
                      }
                    }

                    if (res.platformUse || res.platformUse === 'true') {
                      this.platformUse = 'true';
                    } else {
                      this.platformUse = 'false';
                    }
                    if (res.applicationName) {
                      this.ruleForm.applicationName = res.applicationName;
                    }
                    if (res.appIntroduce) {
                      this.ruleForm.appIntroduce = res.appIntroduce;
                    }
                    if (res.appIntroduceInshort) {
                      this.ruleForm.appIntroduceInshort =
                        res.appIntroduceInshort;
                    }
                    if (res.appFeatures) {
                      this.ruleForm.appFeatures = res.appFeatures;
                    }
                    if (res.screenshotFileName) {
                      res.screenshotFileName.split(',').forEach((el, index) => {
                        const path = res.screenshotFilePath.split(',');
                        this.shotscreenFiles.push({
                          name: el,
                          url: path[index]
                        });
                      });
                      this.spread2 = true;
                      this.spread2Value = true;
                    }
                    this.repeatUse = `${res.repeatUse === 'true'}`;
                    this.repeatUseString = `${res.repeatUse === 'true'}`;
                    this.spread1 = res.application === 'true';
                    this.spread1Value = res.application === 'true';
                    this.platformUse = `${res.platformUse === 'true'}`;
                    this.platformUseString = `${res.platformUse === 'true'}`;
                    this.ruleForm.chooseList = [];
                    const tatgetList = res.targetLanguageId.split(',');
                    targetLang.forEach(elLang => {
                      tatgetList.forEach(elList => {
                        if (elList === elLang.value) {
                          this.ruleForm.chooseList.push(elLang);
                        }
                      });
                    });

                    if (res.sourceFileName) {
                      res.sourceFileName.split(',').forEach((el, index) => {
                        const path = res.originalPath.split(',');
                        this.ruleForm.sourceFiles.push({
                          name: el,
                          url: path[index]
                        });
                      });
                      this.spread2 = true;
                      this.spread2Value = true;
                      this.ruleForm.sourceFilesInput = 'true';
                    }

                    this.num = 0;
                  }
                }
              );
            } else {
              this.num = 1;
            }
          } else {
            this.$promptMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.code ? res.code : res.ret.code
              })`,
              this.$t('ctp.tips')
            );
          }
        },
        err => {
          this.$promptMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.tips')
          );
          return err;
        }
      );
    },
    showSaveOrder(back) {
      if (!this.pageInfoNotSaved) {
        sessionStorage.setItem('savePromptFlag', 'N');
        this.$router.push({ path: '/cp/list' });
        return;
      }
      this.saveOrder = this.pageInfoNotSaved;
      if (back) {
        this.backFlag = true;
      }
    },
    handleBack() {
      this.saveOrder = false;
      if (this.backFlag) {
        sessionStorage.setItem('savePromptFlag', 'N');
        this.$router.push({ path: '/cp/list' });
      }
    }
  }
};
</script>

<style lang='scss' >
.el-upload-list__item .el-icon-close-tip {
  display: none !important;
}
.el-icon-upload-success .el-icon-circle-check {
  display: none !important;
}
.el-upload-list__item-status-label {
  display: none !important;
}
#icon-uploadTip .agc-icon--static {
  display: inline-block;
  vertical-align: middle;
}
.el-upload-list--text {
  display: block !important;
}
</style>

<style scoped>
@import 'order.css';
</style>
